h1.success {
  color: #50CD01FF;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
p.success {
  color: #404F5E;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size:20px;
  margin: 0;
}
.checkmark , .fa-remove i {
  color: #50CD01FF;
  font-size: 100px;
  line-height: 200px;
}
.card {
  background: white;
  padding: 60px;
  border-radius: 15px;
  display: inline-block;
  margin: 0 auto;
  max-width: 500px;
}