@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/react-datepicker/dist/react-datepicker.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control {
  outline: none!important;
  box-shadow: none!important;
}

.form-control:focus{
  border-color: #74C92C!important;
  box-shadow: none !important;
}

.css-1pahdxg-control {
  border-color: #74C92C!important;
  /*box-shadow: 0 0 0 0.05rem rgba(116, 201, 44, 0.25) !important;*/
  box-shadow: none !important;
}

.request-btn, .continue-btn {
  outline: none!important;
  box-shadow: none!important;
  background-color: #74C92C!important;
  color: white;
  text-transform: capitalize!important;
}

.request-btn , .btn-outline-primary {
  border-color: #74C92C!important;
}

.root-widget {
  width: 100%!important;
  float: left!important;
}